.hi_table__container::-webkit-scrollbar {
  width: 6px;
  height:6px;
  display: block;
}
.hi_table__container::-webkit-scrollbar-track {
  background-color: transparent;
}
.hi_table__container::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: gray;
}
.hi_table__container::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}
